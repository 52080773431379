import React, { Component } from 'react'

export default class Inspiration extends Component {
  render() {
    return (
      <div>
       <a href="https://skivvyj.wordpress.com/"> <h1>Gordon Parks Jr., Americana, Nostalgia, Gondry, Dayton and Farris, Sednaoui, Sophie Muller, Hammer and Tongs, Kevin Kerslake, Atlas Sound, Deerhunter, Beach House, Japan, nostalgia (did I mention that already?),
           Guided By Voices, Smashing Pumpkins, The W.I.Z., Paul Hunter, Josh Fauver (r.i.p),
          PJ Harvey, Valerie and Her Week of Wonders, the Zapruder film, Madonna, A Funeral Parade of Roses, Tokyo,
          Paris, NYC, Sonic Youth, Kansas, Failure, Appropriation, Guilt, Forgiveness, Richard Prince,
          Jack Smith... </h1></a>
      
        <img className="img-fluid" align="center" src="https://images.unsplash.com/photo-1531384370597-8590413be50a?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=7a11b4f191e3c730649b183469f782c5&auto=format&fit=crop&w=500&q=60"/>
     </div>
    )
  }
}
